<template>
  <HeaderComponent @expandListBlock="handleExpand"/>

  <main>
    <section class="container">
      <div class="row">
        <div :class="menuClass">
          <div class="stat-card card handle-expand">
            <ul>
              <li class="">
                <div id="user-projects-list" 
                  v-if="isAuthenticated" 
                >
                  <div class="custom-select mb-3">
                    <select name="" id="" 
                      @change="handleChangeProject" 
                      v-model="selected_project"
                    >
                      <option :value="0" disabled>Select project</option>
                      <option 
                        v-for="project in projects"
                        :value="project.id" 
                        :key="project.id"  
                      >
                        {{ project.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <h3>Services</h3>
              </li>
              <li 
                v-for="(link, i) in getLinks" 
                :key="i"
                :class="{
                  'disabled': link.disabled,
                  'current': isCurrent(link.route) == true 
                }"
                class="mb-1"
              >
                <router-link :to="link.route">
                  <button class="">
                    {{ link.label }}
                  </button>
                </router-link>
              </li>
              <template v-if="selected_project > 0">
                <li class="mb-3 mt-3">
                  <h3>Resources</h3>
                </li>
                <li 
                  v-for="link in getResourcesLinks" 
                  :key="link.url" 
                  :class="{
                    'current': isCurrent(link.url)
                  }" 
                  class="mb-1 text-container"
                >
                  <router-link :to="link.url">
                    <button>
                      {{ link.label }}
                    </button>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <div :class="contentClass" id="layout-main-content">
          <slot></slot>
        </div>
      </div>
    </section>
  </main>

  <FooterComponent/>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import FooterComponent from "./Footer/FooterComponent.vue";
import HeaderComponent from "./Header/HeaderComponent.vue";
import { storeToRefs } from 'pinia'
import { useInterfaceStore } from '@/stores/interface'
import { useLogto } from "@logto/vue";

const { isAuthenticated } = useLogto();
const interfaceStore = useInterfaceStore();
const route = useRoute();
const router = useRouter()

const { projects, selected_project } = storeToRefs(interfaceStore)
const isMenuExpanded = ref(false);  

const menuClass = computed(() => {
  return isMenuExpanded.value ? "col-lg-2" : "col-lg-3";
});
const contentClass = computed(() => {
  return isMenuExpanded.value ? "col-lg-10" : "col-lg-9";
});

const handleExpand = (value) => {
  isMenuExpanded.value = value;
};
function isCurrent(link){
  if(link == "/") return false;
  return route.path.includes(link);
}

function handleChangeProject(e) {
  selected_project.value = e.target.value
  router.push({ params: { project_id: e.target.value }})
}

const getLinks = computed(() => {
  return [
    {
      route: `/overview/${selected_project.value}`,
      label: "Overview",
      icon: "fa-solid fa-user",
      disabled: false,
    },
    // {
    //   route: `/databases/${selected_project.value}`,
    //   label: "Databases",
    //   icon: "fa-solid fa-folder",
    //   disabled: false,
    // },
    // {
    //   route: `/kubernetes/${selected_project.value}`,
    //   label: "kubernetes",
    //   icon: "",
    //   disabled: false,
    // },
    // {
    //   route: `/compute_resources/${selected_project.value}`,
    //   label: "Compute Resources",
    //   icon: "",
    //   disabled: false
    // },
    // {
    //   route: `/volumes/${selected_project.value}`,
    //   label: 'Volumes',
    //   icon: '',
    //   disabled: false,
    // }
  ]
})

const getResourcesLinks = computed(() => {
  return [
    {
      label: "Cores",
      url: `/services/${selected_project.value}/cores`
    },
    {
      label: "Memory",
      url: `/services/${selected_project.value}/memory`,
    },
    {
      label: 'Storage',
      url: `/services/${selected_project.value}/storage`,
    },
    {
      label: 'Databases',
      url: `/databases/${selected_project.value}`,
    },
    {
      label: 'Bandwidth',
      url: `/services/${selected_project.value}/bandwidth`,
    },
    {
      label: 'Load Balancers',
      url: `/services/${selected_project.value}/load_balancers`
    },
    {
      label: 'Kubernetes Clusters',
      url: `/kubernetes/${selected_project.value}`,
    },
    {
      label: 'Volumes Total Size',
      url: `/services/${selected_project.value}/total_volume_gigabytes`
    },
    {
      label: 'Development Points',
      url: '/development-point/history'
    }
  ]
})
</script>

<style>
.btn.grey {
  background: #f8f8fa;
  border: solid 1px #d0d5dd;
}

.current button{
  font-weight: bold;
  color: #184ABC
}

#user-projects-list {
  margin: 0 0 auto auto;
}

#user-projects-list select {
  border: solid 2px #fff;
  border-left-color: #0053ff;
  border-radius: 2px;
  padding: 0;
}

#user-projects-list option {
  color: #101828;
}

.text-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.handle-expand {
  overflow-x: scroll;
}
</style>