/** @type {import('vue-router').NavigationGuardWithThis} */


const parseLocalStorage = (key) => {
  const value = localStorage.getItem(key) 

  if(value === 'undefined' || value === null || value === 0) {
    return null;
  }

  return JSON.parse(value)
}


export async function projectGuard({ to, interfaceStore }) {
  const project = parseLocalStorage('selected_project');

  const ROUTER = [
    'billing',
    'projects',
    'products',  
    'volumes',
    'databases',
    'development',
    'services',
    'overview'
  ]

  if (!project && ROUTER.some(route => to.fullPath.includes(route))) {
    return "/my-account";
  }
}
