import { defineStore } from 'pinia'

interface IProject {
  id: number
  name: string
}

interface IInterface {
  projects: IProject[]
  selected_project?: number
}

export const useInterfaceStore = defineStore('interface', {
  state: (): IInterface => {
    return { 
      projects: [],
      selected_project: 0 
    }
  },
  getters: {
    getProjects: state => {
      return state.projects
    },
    getSelectedProject: state => {
      return state.selected_project
    },
    getSelectedProjectObject: state => {
      const selectedProject = localStorage.getItem('selected_project')
      if(selectedProject && selectedProject !== 'undefined') {
        return JSON.parse(selectedProject)
      }
      return null
    }
  },
  actions: {
    setProjects(projects: IProject[]): void {
      this.projects = projects
    },
    reset(): void {
      localStorage.removeItem('selected_project')
      this.selected_project = 0
    },
    setSelectedProject(project_id: number): void {
      this.selected_project = project_id
      const selectedProject = this.projects.find(project => project.id === project_id)
      localStorage.setItem('selected_project', JSON.stringify(selectedProject))
    }
  }
})