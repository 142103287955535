import { format } from "date-fns";
import instance from "./config";

export default {
  listAll: async function (jwt: string) {
    try {
      const response = instance.get("/projects", {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getStatus: async function (jwt: string, id: number) {
    try {
      const response = instance.get(`/projects/${id}/status`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getHistory: async function (id, start, end, jwt: string, range = null) {
    try {
      const params = {
        start: null,
        end: null,
        range,
      };
      if (start) params.start = format(start, "yyyy-MM-dd");
      if (end) params.end = format(end, "yyyy-MM-dd");
      const response = instance.get(`/projects/${id}/history`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getFromUser: async function (jwt: string, customer_id: string): Promise<any> {
    try {
      const { data } = await instance.get(`/projects/customer/${customer_id}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      if (data.success) return data.data;
      else return [];
    } catch (error) {
      return [];
    }
  },
  update: async function (jwt, param_obj) {
    try {
      const response = await instance.put("/projects", param_obj, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateLess: async function (jwt, param_obj) {
    try {
      const response = await instance.put("/projects/less", param_obj, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  points: async function (jwt, project_id, payload) {
    try {
      const response = await instance.put(
        `/projects/${project_id}/points`,
        payload,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProject: async function (jwt, id) {
    try {
      const response = instance.get(`/projects/${id}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProducts: async function (jwt, id) {
    try {
      const response = instance.get(`/projects/${id}/products`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  create: async function (jwt, payload) {
    try {
      const response = await instance.post("/projects", payload, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getBillingHistory: async function (jwt, project_id) {
    try {
      const response = await instance.get(
        `/projects/${project_id}/billing_history`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  removeProduct: async function (jwt, project_id, project_product_id) {
    try {
      const { data } = await instance.delete(
        `/projects/${project_id}/${project_product_id}`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  delete: async function (jwt: string, project_id: number): Promise<any> {
    try {
      const { data } = await instance.delete(`/projects/${project_id}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  cancelSubscription: async function (
    jwt: string,
    project_id: number,
    period: string
  ): Promise<any> {
    try {
      const response = await instance.delete(
        `/projects/${project_id}/subscription`,
        {
          params: {
            period,
          },
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
