import instance from "./config";

interface ICustomerCreatePayload {
  customer_form: {
    name: string;
    username: string;
    email: string;
    password_one: string;
    password_two: string;
    phonenumber: string;
  };
  payment_method_form: {
    name: string;
    cc_number: string;
    exp_month: number;
    exp_year: number;
    cvc: number;
  };
}

export default {
  create: async function (payload: ICustomerCreatePayload, jwt: string): Promise<any> {
    try {
      const { data } = await instance.post("/customers/create", payload, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      console.log("customer created");
      return data;
    } catch (error) {
      throw error;
    }
  },
  listAll: async function (jwt, onlyInactive) {
    try {
      const response = await instance.get(
        `/customers?onlyInactive=${onlyInactive}`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  get: async function (jwt, id) {
    try {
      const response = await instance.get(`/customers/${id}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getMe: async function (jwt: string): Promise<any> {
    try {
      const { data } = await instance.get("/customers/me", {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return data.data;
    } catch (error) {
      return null;
    }
  },
  updateById: async function (jwt, id, payload) {
    try {
      const response = await instance.put(`/customers/${id}`, payload, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async function (jwt, payload) {
    try {
      const response = await instance.put(`/customers`, payload, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async function (jwt, id) {
    try {
      const response = await instance.post(
        `/customers/${id}/password-reset`,
        {},
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  disabled: async function (jwt, id, payload) {
    try {
      const response = await instance.put(`/customers/${id}`, payload, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  
  delete: async function (jwt, id) {
    try {
      const response = await instance.delete(`/customers/${id}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
};
